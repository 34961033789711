import React, { useContext } from 'react';

// context
import { DeviceContext } from 'contexts/DeviceContext';

// types
import type { MultiPageHeroVariantProps } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/types';

// utils
import { getWebsitePlaceholderAsset } from 'components/manage/EditWebsite/common/HeroCustomizer/utils';

// components
import WebsiteSlideshow from 'components/publicWebsiteV2/common/WebsiteSlideshow';

// styles
import { BaseHero } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';
import {
  BottomPhotoSized,
  DesktopImagesContainer,
  StyledDesktopBaseHero,
  TopPhotoSized,
  STACKED_PHOTO_LENGTH,
} from 'components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroStacked/MultiPageHeroStacked.styles';

const MultiPageHeroStacked: React.FC<MultiPageHeroVariantProps> = ({
  images,
  inPreview,
  overrideMobileHeight,
}) => {
  const { device } = useContext(DeviceContext);

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  const topPhoto = images?.find(image => image?.position === 1);
  const bottomPhoto = images?.find(image => image?.position === 2);

  const slideshowImages = [
    topPhoto ? topPhoto?.image_url : getWebsitePlaceholderAsset('STACKED', isDesktop, 1),
    bottomPhoto ? bottomPhoto?.image_url : getWebsitePlaceholderAsset('STACKED', isDesktop, 2),
  ];

  return isDesktop ? (
    <StyledDesktopBaseHero
      overrideMobileHeight={overrideMobileHeight}
      data-testid="MultiPageHeroStacked"
    >
      <DesktopImagesContainer>
        <TopPhotoSized
          src={topPhoto ? topPhoto?.image_url : getWebsitePlaceholderAsset('STACKED', isDesktop, 1)}
          alt="Hero Image 1"
          containerWidth={STACKED_PHOTO_LENGTH}
        />
        <BottomPhotoSized
          src={
            bottomPhoto
              ? bottomPhoto?.image_url
              : getWebsitePlaceholderAsset('STACKED', isDesktop, 2)
          }
          alt="Hero Image 2"
          containerWidth={STACKED_PHOTO_LENGTH}
        />
      </DesktopImagesContainer>
    </StyledDesktopBaseHero>
  ) : (
    <BaseHero overrideMobileHeight={overrideMobileHeight} data-testid="MultiPageHeroStacked">
      <WebsiteSlideshow slideshowImages={slideshowImages} inPreview={inPreview} />
    </BaseHero>
  );
};

export default MultiPageHeroStacked;

import React, { FC, useRef } from 'react';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import {
  SlideshowContainer,
  SlideshowSlider,
  SlideSized,
  TimerBarContainer,
  TimerContainer,
  Timer,
} from './WebsiteSlideshow.styles';

export type WebsiteSlideshowProps = {
  slideshowImages: Array<string>;
  inPreview?: 'DESKTOP' | 'MOBILE';
  isSinglePage?: boolean;
};

const WebsiteSlideshow: FC<WebsiteSlideshowProps> = ({
  slideshowImages,
  inPreview,
  isSinglePage,
}) => {
  const delay = 6000;
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef<ReturnType<typeof setInterval> | null>(null);

  React.useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => setIndex(prevIndex => (prevIndex === slideshowImages.length - 1 ? 0 : prevIndex + 1)),
      delay
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [slideshowImages.length, index]);

  // TODO: move outside WebsiteSlideshow?
  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  return (
    <SlideshowContainer ref={containerRef}>
      <SlideshowSlider isSinglePage={isSinglePage}>
        {slideshowImages.map((link, idx) => (
          <SlideSized
            imagesLength={slideshowImages.length}
            isSinglePage={isSinglePage}
            key={`hero-slideshow-image-${idx}`}
            inPreview={inPreview}
            src={link}
            alt={`Hero Slideshow Image ${idx}`}
            containerWidth={containerWidth}
          />
        ))}
      </SlideshowSlider>
      <TimerBarContainer inPreview={inPreview}>
        {slideshowImages.map((_, idx) => (
          <TimerContainer key={idx} inPreview={inPreview}>
            <Timer key={`slideshow-timer-${idx}`} active={idx === index} inPreview={inPreview} />
          </TimerContainer>
        ))}
      </TimerBarContainer>
    </SlideshowContainer>
  );
};

export default WebsiteSlideshow;
